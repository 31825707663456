<template>
  <!-- 次级指标分配 -->
  <div class="mainform">
    <div class="mainHeader">次级指示<span @click="back" class="back">返回></span></div>
    <div class="exportData">
      <el-button class="textBtn" @click="AddRework" type="text">+新增</el-button>
    </div>
    <div class="tab-tit">
      <span class="tableHeader">考评指标代码: {{evaluationCode}}</span>
      <span class="tableHeader">考评指标描述: {{evaluationName}}</span>
    </div>
    <div class="tableList" >
      <div class="table">
        <el-table :data="tableData" ref="tabledeatailData" max-height="350" style="width: 100%">
          <el-table-column prop="sequenceNumber" label="序号" width="80px" :show-overflow-tooltip="true">
            <template #default="scope">
              <el-input :disabled=scope.row.showDisabled v-model="scope.row.sequenceNumber" placeholder=""></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="secondaryCode" label="次级指标代码" width="220px" :show-overflow-tooltip="true">
            <template #default="scope">
              <el-input :disabled=scope.row.showDisabled v-model="scope.row.secondaryCode" placeholder=""></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="secondaryName" label="次级指标描述" width="120px" :show-overflow-tooltip="true">
            <template #default="scope">
              <el-input :disabled=scope.row.showDisabled v-model="scope.row.secondaryName" placeholder=""></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="strExplain" label="次级指标详细说明" width="150px" :show-overflow-tooltip="true">
            <template #default="scope">
              <el-input :disabled=scope.row.showDisabled v-model="scope.row.strExplain" placeholder=""></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="upper" label="理论上限值" width="" :show-overflow-tooltip="true">
            <template #default="scope">
              <el-input :disabled=scope.row.showDisabled v-model="scope.row.upper" placeholder=""></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="weight" label="权重比率(%)" width="" :show-overflow-tooltip="true">
            <template #default="scope">
              <el-input :disabled=scope.row.showDisabled v-model="scope.row.weight"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="zbMx" label="指标明细" width="" :show-overflow-tooltip="true">
            <template #default="scope">
              <el-button size="mini" type="text" @click="scoredetail(scope.row)">指标明细</el-button>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100px">
            <template #default="scope">
              <el-button v-if="scope.row.edStart === 1" type="text" size="mini" @click="edit(scope.row)">编辑</el-button>
              <el-button v-else type="text" size="mini" @click="preservation(scope.row)">保存</el-button>
              <el-button type="text" size="mini" @click="eliminateDinlog(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="tablepage">
          <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size = pageSize
          layout="total,  prev, pager, next, jumper"
          :total = total>
          </el-pagination>
        </div>
      </div>
    </div>
    <dialog-box ref="AddNewData" componentName="SubcriteriAddDialog" dialogWidth="800px" :dialogShow="AddcheckDialogShow" @handleClose='handleClose' title="新增" :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{label:'确认',action:'addPrework', type:'primary', size:'mini'}]" @addPrework="peworkBtn"></dialog-box>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
// import { tableField, tableData } from './js/subcriteriAallot'
import { request } from '@/assets/js/http.js'
export default {
  components: { ...Component.components },
  name: 'SubcriteriAallot',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 330) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      editStart: 1,
      parentId: this.$route.query.id,
      evaluationCode: this.$route.query.code,
      evaluationName: this.$route.query.name,
      pageNum: 1, // 当前第几页
      pageSize: pageSize, // 每页显示多少条
      total: 0, // 共多少条
      AddcheckDialogShow: false,
      tableData: []
    }
  },
  mounted () {
  },
  created () {
    this.getDataList()
  },
  methods: {
    // ID获取次级指标列表
    getDataList () {
      const parentId = this.parentId
      const obj = {
        parentId
      }
      request('/api/evaluation/secondary/queryAll?pageNum=' + this.pageNum + '&' + 'pageSize=' + this.pageSize, 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.records
          this.total = res.data.total
          this.tableData.forEach(item => {
            item.edStart = 1
            item.showDisabled = true
          })
        }
      })
    },

    // 新增弹框
    AddRework () {
      this.AddcheckDialogShow = true
    },
    // 点击新增里面的确认按钮
    peworkBtn () {
      var newsArr = this.$refs.AddNewData.$refs.content.form
      newsArr.edStart = 1
      newsArr.showDisabled = true
      if (!newsArr.sequenceNumber) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请输入序号'
        })
        return false
      }
      if (!newsArr.secondaryCode) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请输入次级指标代码'
        })
        return false
      }
      if (!newsArr.secondaryName) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请输入次级指标描述'
        })
        return false
      }
      if (!newsArr.strExplain) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请输入次级指标详细说明'
        })
        return false
      }
      if (!newsArr.upper) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请输入理论上线值'
        })
        return false
      }
      if (!newsArr.weight) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请输入权重比率'
        })
        return false
      }

      const obj = {
        parentId: this.parentId,
        sequenceNumber: newsArr.sequenceNumber,
        secondaryCode: newsArr.secondaryCode,
        secondaryName: newsArr.secondaryName,
        strExplain: newsArr.strExplain,
        upper: newsArr.upper,
        weight: newsArr.weight,
        status: true
      }
      request('/api/evaluation/secondary/save', 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.$message({
            showClose: true,
            type: 'success',
            message: '添加成功'
          })
          this.getDataList()
        }
      })
      this.AddcheckDialogShow = false
    },
    // 保存
    preservation (row) {
      if (!row.sequenceNumber) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请输入序号'
        })
        return false
      }
      if (!row.secondaryCode) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请输入次级指标代码'
        })
        return false
      }
      if (!row.secondaryName) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请输入次级指标描述'
        })
        return false
      }
      if (!row.strExplain) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请输入次级指标详细说明'
        })
        return false
      }
      if (!row.upper) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请输入理论上线值'
        })
        return false
      }
      if (!row.weight) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请输入权重比率'
        })
        return false
      }
      const obj = {
        ...row
      }
      request('/api/evaluation/secondary/update', 'POST', obj).then((res) => {
        if (res.code === '200') {
          row.edStart = 1
          row.showDisabled = true
          this.$message({
            showClose: true,
            type: 'success',
            message: '修改成功'
          })
          this.getDataList()
        }
      })
    },
    // 编辑
    edit (row) {
      row.edStart = 2
      row.showDisabled = false
    },

    // 删除
    eliminate (row) {
      request('/api/evaluation/secondary/delete?id=' + row.id, 'post').then((res) => {
        if (res.code === '200') {
          this.$message({
            showClose: true,
            type: 'success',
            message: '删除成功'
          })
          this.getDataList()
        }
      })
    },
    // 删除弹框
    eliminateDinlog (row) {
      this.$confirm('确认要删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.eliminate(row)
      })
    },
    // 跳转指标细项
    scoredetail (data) {
      this.$router.push({
        params: { viewName: 'Indicatorsdetail' },
        query: {
          id: data.id,
          code: data.secondaryCode,
          name: data.secondaryName
        }
      })
    },
    // 关闭弹窗
    handleClose () {
      this.AddcheckDialogShow = false
    },
    // 每页显示多少条
    handleSizeChange (val) {
      this.pageSize = val
      this.getDataList()
    },
    // 当前第几页
    handleCurrentChange (val) {
      this.pageNum = val
      this.getDataList()
    },
    // 返回
    back: () => {
      window.history.back()
    }
  }
}
</script>
<style scoped lang="scss">
  @import '@/assets/css/elForm.scss';
  @import '@/assets/css/form.scss';
  @import '@/assets/css/tableList.scss';
  .exportData{
    padding: 0 30px;
    .el-button{
      font-size: 16px;
    }
  }
  .tab-tit{
    margin: 0 30px 0;
    padding-top: 15px;
    border-top: 1px solid #eee;
  }
  .mainform{
    .tableHeader{
      margin-right: 55px;
      color: #606266;
      font-size: 14px;
    }
    .action{
      margin-top: 20px;
    }
    :deep(.tableList){
      padding: 20px 30px;
    }
  }
  .tableList {
    .table{
      :deep(.el-table td) {
          padding: 4px 0;
      }
      :deep(.el-input__inner){
        height:34px;
        line-height: 34px;
      }
    }
    .is-disabled{
      :deep(.el-input__inner){
        background: none;
        border: none;
        color: #606266;
        padding: 0;
      }
      :deep(.el-select__caret){
        display: none;
      }
    }
  }
</style>
